<template>
  <div id="user-management">
    <full-loader v-show="loading"></full-loader>
    <v-container fluid class="wrapper">
      <!-- breadcrum and previous router -->
      <div class="breadcrumb-header">
        <v-btn text small icon color="primary" @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-breadcrumbs :items="breadcrumbItems" divider="/"></v-breadcrumbs>
      </div>
      <!-- label and nav button -->
      <div class="header">
        <h3>User Management:</h3>
        <!-- <div class="right-align-btn">
          <v-btn small rounded class="nav-btn" color="primary" link :to="{name: 'roles'}">Roles</v-btn>
          <v-btn
            small
            rounded
            class="nav-btn"
            color="primary"
            link
            :to="{name: 'departments'}"
          >Departments</v-btn>
          <v-btn small rounded class="nav-btn" color="primary" link :to="{name: 'teams'}">Teams</v-btn>
        </div> -->
      </div>

      <!-- filter -->
      <!-- <v-card class="mx-auto" outlined>
        <v-container>
        <div class="card-sub-label">Search by:</div>

        <v-row flex class="fliter-box">
          <v-col cols="12" md="1">Search by:</v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="2"
            v-for="(filter, i) in Object.keys(level_list)"
            :key="i"
          >
            <v-autocomplete
              dense
              :items="level_list[filter]"
              item-text="name"
              :label="filter"
              v-model="filter_items[filter]"
              outlined
              clearable
              color="appcolor"
              @input="selectSeacrhChange(filter_items[filter], filter)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" xs="12" sm="6" class="ma-0"
            md="1" >
            <v-btn fab small color="appcolor" dark>
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        </v-container>
      </v-card> -->

      <!-- User add / update/ view -->
      <v-layout warp>
        <!-- user list & filter list -->
        <v-flex sm12 md6>
          <!-- user list  -->
          <v-card class="mx-auto ma-5 pull-left" max-width="95%" outlined dark>
            <v-container>
              <v-text-field
                clearable
                flat
                solo
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
                v-model="filter_items.search_key"
                @input="search"
              ></v-text-field>
              <v-divider></v-divider>
              <v-list style="height: 70vh; overflow: auto; position: relative">
                <div
                  class="container list-wrapper"
                  style="cursor: pointer"
                  @click="userEditList(user)"
                  :class="{
                    active:
                      selectedUserData &&
                      selectedUserData._id == user._id &&
                      editFlag == 'edit',
                  }"
                  v-for="(user, i) in temp_users"
                  :key="i"
                >
                  <div class="row">
                    <div class="col-sm-4" style="display: flex">
                      <div class="sub-head">
                        <v-icon
                          dark
                          right
                          :class="{
                            'active-icon':
                              selectedUserData &&
                              selectedUserData._id == user._id &&
                              editFlag == 'edit',
                          }"
                        >
                          mdi-account
                        </v-icon>
                      </div>
                      <div>{{ user.fullname ? user.fullname : "-" }}</div>
                    </div>
                    <div class="col-sm-5" style="display: flex">
                      <div class="sub-head">
                        <v-icon
                          dark
                          right
                          :class="{
                            'active-icon':
                              selectedUserData &&
                              selectedUserData._id == user._id &&
                              editFlag == 'edit',
                          }"
                        >
                          mdi-email-check-outline
                        </v-icon>
                      </div>
                      <div>{{ user.email ? user.email : "-" }}</div>
                    </div>
                    <div class="col-sm-3" style="display: flex">
                      <div class="sub-head">
                        <v-icon
                          dark
                          right
                          :class="{
                            'active-icon':
                              selectedUserData &&
                              selectedUserData._id == user._id &&
                              editFlag == 'edit',
                          }"
                        >
                          mdi-domain
                        </v-icon>
                      </div>
                      <div>{{ user.role ? user.role : "-" }}</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12" style="display: flex">
                      <div class="sub-head">Created By:</div>
                      <div>
                        {{ user.createdBy ? user.createdBy.email : "-" }}
                      </div>
                    </div>
                  </div>
                </div>
                <v-divider></v-divider>
                <div
                  v-if="temp_users && temp_users.length == 0"
                  class="no-data"
                >
                  No Users
                </div>
              </v-list>
            </v-container>
          </v-card>
        </v-flex>

        <!-- user form -->
        <v-flex sm12 md6>
          <v-card class="mx-auto ma-5" outlined>
            <v-container>
              <v-form ref="form" v-model="valid">
                <v-row class="right-wrapper">
                  <v-container>
                    <v-row>
                      <label style="margin: 0px 10px" class="heading"
                        >Email</label
                      >
                      <v-col cols="12">
                        <v-text-field
                          label="Email"
                          v-model="form.email_id"
                          :rules="rules.email_id"
                          dense
                        ></v-text-field>
                      </v-col>
                      <label style="margin: 0px 10px" class="heading"
                        >Full Name</label
                      >
                      <v-col cols="12">
                        <v-text-field
                          label="Full Name"
                          v-model="form.employee_name"
                          :rules="rules.employee_name"
                          dense
                        ></v-text-field>
                      </v-col>
                      <label style="margin: 0px 10px" class="heading"
                        >Select Role</label
                      >
                      <v-col v-if="loggedUser" cols="12">
                        <v-autocomplete
                          label="Select Role"
                          :items="
                            loggedUser && loggedUser.role == 'Super Admin'
                              ? roles
                              : loggedUser.role == 'Admin'
                              ? roles_admin
                              : loggedUser.role == 'AdminClient'
                              ? roles_admin_client
                              : null
                          "
                          item-text="name"
                          v-model="form.role"
                          :rules="rules.role"
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <label style="margin: 0px 10px" class="heading" v-if="loggedUser && loggedUser.role != 'AdminClient'"
                        >Select Subsidiary</label
                      >
                      <v-col v-if="loggedUser && loggedUser.role != 'AdminClient'" cols="12">
                        <v-autocomplete
                          label="Select Subsidiary"
                          :items="allSubsidiary ? allSubsidiary : []"
                          item-text="name"
                          item-value="_id"
                          v-model="form.subsidiary"
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <label style="margin: 0px 10px" class="heading"
                        >Access To</label
                      >
                      <v-col cols="12">
                        <v-row style="margin: 0px 20px">
                          <v-col cols="12" sm="4" md="4">
                            <v-checkbox
                              v-model="form.allowWebAccess"
                              label="Website"
                              color="primary"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <v-checkbox
                              v-model="form.allowDesktopAccess"
                              label="Desktop App"
                              color="primary"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <v-checkbox
                              v-model="form.allowProcessingPanel"
                              :disabled="checkForRole()"
                              label="Processing Panel"
                              color="primary"
                              hide-details
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-col>
                      <label style="margin: 0px 10px" class="heading"
                        >Project Access</label
                      >
                      <v-col cols="12" style="text-align: center">
                        <multiselect
                          v-model="form.zone"
                          tag-placeholder="Add this as new project"
                          placeholder="Add a project"
                          :options="level_list.zone"
                          :multiple="true"
                          :taggable="true"
                          @tag="updateEquips"
                          label="name"
                          track-by="name"
                        ></multiselect>
                          <p v-show="isValidProject && form.zone && form.zone.length == 0" class="float-left mb-0 text-sm-caption red--text">Project is a required field</p>
                      </v-col>
                      <div style="width: 100%;" v-if="form.zone && form.zone.length > 0" v-for="zone in form.zone" :key="zone._id">
                        <label  style="margin: 0px 10px" class="heading" v-if="zone && zone.name != 'All Projects'"
                        >Section Access for {{ zone.name }}</label
                      >
                      <v-col cols="12" style="text-align: center">
                        <multiselect
                          v-model="zone.sections"
                          tag-placeholder="Add this as new section"
                          placeholder="Add a section"
                          :options="zone.sections"
                          :multiple="true"
                          :taggable="true"
                          label="name"
                          track-by="name"
                          @tag="updateEquips"
                        ></multiselect>
                      </v-col>
                      </div>
                      <label style="margin: 0px 10px" class="heading"
                        >Active Status</label
                      >
                      <v-col cols="12">
                        <v-autocomplete
                          label="Active Status"
                          :items="active_status"
                          item-text="name"
                          v-model="form.is_active"
                          :rules="rules.active"
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <label
                        style="margin: 0px 10px"
                        v-if="!editFlag || editFlag != `edit`"
                        class="heading"
                        >Password</label
                      >
                      <v-col cols="12" v-if="this.editFlag != `edit`">
                        <v-text-field
                          type="password"
                          label="Password"
                          v-model="form.password"
                          :rules="
                            this.editFlag == `edit` ? true : rules.password
                          "
                          autocomplete="new-password"
                          dense
                        ></v-text-field>
                      </v-col>
                      <label
                        style="margin: 0px 10px"
                        v-if="!editFlag || editFlag != `edit`"
                        class="heading"
                        >Confirm Password</label
                      >
                      <v-col cols="12" v-if="this.editFlag != `edit`">
                        <v-text-field
                          type="password"
                          label="Confirm Password"
                          v-model="form.confirm_password"
                          :rules="
                            this.editFlag == `edit`
                              ? true
                              : rules.confirm_password.concat(
                                  passwordConfirmationRule
                                )
                          "
                          autocomplete="new-password"
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="action-button">
                         <v-btn
                          color="error"
                          class="mr-4"
                          v-show="selectedUserData"
                          @click="
                            dialog = true
                          "
                          >CHANGE PASSWORD</v-btn
                        >
                        <v-btn
                          color="warning"
                          class="mr-4"
                          @click.prevent="$refs.form.reset()"
                          @click="
                            editFlag = null;
                            form.zone = [];
                          "
                          >RESET</v-btn
                        >
                        <v-btn
                          type="submit"
                          color="success"
                          class="mr-4"
                          @click.prevent="submit"
                          >SUBMIT</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-row>
              </v-form>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="400px">
          <v-card>
            <v-card-title>
              <span class="headline"
                >Change Password<span class="email"
                  >( {{ selectedUserData && selectedUserData.email ? selectedUserData.email : "User" }} )</span
                ></span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="validPass">
                  <!-- <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Old Password*"
                        type="password"
                        :rules="rules.oldPassword"
                        v-model="oldPassword"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row> -->
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="New Password*"
                        v-model="password"
                        :rules="rulesAdmin.password"
                        type="password"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Confirm Password*"
                        v-model="confirm_password"
                        :rules="
                          rulesAdmin.confirm_password.concat(
                            passwordConfirmationRuleAdmin
                          )
                        "
                        type="password"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false; $refs.form.reset()">
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!validPass"
                @click.prevent="submitPassword"
                @click="dialog = false"
              >
                Change
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
  </div>
</template>

<script>
import Mixins from "@/mixin";
import AdminAPI from "@/services/admin_api";
import Multiselect from "vue-multiselect";
import FullLoader from "../components/full-loader.vue";

export default {
  name: "user-management",
  mixins: [Mixins],
  components: {
    Multiselect,
    "full-loader": FullLoader,
  },
  data() {
    return {
      // breadcrumb
      breadcrumbItems: [
        {
          text: "User Management",
          disabled: false,
          to: { name: "user-management" },
          exact: true,
        },
      ],
      // zone, state, subzone, station, source, feeder list
      level_list: {
        zone: [],
        state: [],
        subzone: [],
        station: [],
        source: [],
        feeder: [],
      },
      level_list_form: {
        zone: [],
        state: [],
        subzone: [],
        station: [],
        source: [],
        feeder: [],
      },
      // level_list_filter: {
      //   zone: null,
      //   state: null,
      //   subzone: null,
      //   station: null,
      //   source: null,
      //   feeder: null
      // },
      // roles list
      roles: ["Admin", "AdminClient", "Inspector", "User"],

      roles_admin: ["AdminClient", "Inspector", "User"],

      roles_admin_client: ["Inspector"],

      allSubsidiary: [],
      // teams list
      teams: [],

      // department list
      active_status: ["Active", "Not Active"],

      // user list
      users: [],

      // temp user list for filter demo
      temp_users: [],

      // filter query data
      filter_items: {
        zone: null,
        state: null,
        subzone: null,
        station: null,
        source: null,
        feeder: null,
        search_key: null,
      },

      // form
      valid: true,
      validPass: true,
      form: {
        employee_name: "",
        password: "",
        confirm_password: "",
        user_id: "TNB User",
        email_id: "",
        role: "Pilot",
        department: "",
        team: "Alpha XT",
        is_active: "",
        allowWebAccess: false,
        allowDesktopAccess: false,
        allowProcessingPanel: false,
        subsidiary: null,
        zone: [],
        state: [],
        subzone: [],
        station: [],
        source: [],
        feeder: [],
        section: [],
      },

      // validation rules
      rules: {
        employee_name: [this.required("Full Name")],
        password: [this.required("Password"), this.passwordStrong()],
        user_id: [],
        email_id: [this.required("Email ID"), this.email()],
        role: [this.required("Role")],
        department: [],
        confirm_password: [
          this.required("Confirm Password"),
          this.passwordStrong(),
        ],
        active: [this.required("Active Status")],
        team: [],
        zone: [this.required("Zone")],
        state: [],
        subzone: [],
        station: [],
        source: [],
        feeder: [],
      },
      rulesAdmin: {
        password: [this.required("Password")],
        confirm_password: [
          this.required("Confirm Password")
        ],
      },
      dialog: false,

      // icon for select all
      select_all_icon: "mdi-checkbox-blank-outline",

      // focused level on form
      focused_level: "zone",

      //add or update
      editFlag: null,
      selectedUserData: null,
      // temp level item for select all box
      tempSelectItem: [],
      loggedUser: null,
      password: null,
      oldPassword: null,
      confirm_password: null,
      loading: false,
      isValidProject: false,
    };
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.form.password === this.form.confirm_password ||
        "Password must match";
    },
    passwordConfirmationRuleAdmin() {
      return () =>
        this.password === this.confirm_password || "Password must match";
    },
  },
  methods: {
    async submitPassword() {
      if (!this.$refs.form.validate()) return;
      let body = {
        new_password: this.password,
        userId: this.selectedUserData._id
      };
       this.loading = true;
      let response = await AdminAPI.post(`changePassword?admin=true`, body);
       this.loading = false;
      if (response.data.status) {
        this.alert("success", "", response.data.success);
        this.$refs.form.reset()
      } else {
        this.alert("error", "", response.data.error);
      }
    },
    checkForRole() {
      if (this.loggedUser && this.loggedUser.role == "Super Admin") {
        return false;
      } else if (
        this.loggedUser &&
        this.loggedUser.role == "Admin" &&
        this.loggedUser.allowProcessingPanel
      ) {
        return false;
      } else {
        return true;
      }
    },
    customLabelEquips(option) {
      return `${option}`;
    },
    updateEquips(selectedValues) {
      this.form.zone.push(selectedValues);
    },
    // select all
    toggle(level) {
      this.$nextTick(() => {
        if (this.level_list_form[level].length === this.form[level].length) {
          this.select_all_icon = "mdi-checkbox-blank-outline";
          this.form[level] = [];
        } else {
          this.select_all_icon = "mdi-checkbox-marked";
          this.form[level] = [...this.level_list_form[level]];
        }
        // update select combo box
        this.updateTempActiveArray();

        // method to get list of all level
        this.selectSeacrhChange(this.form[level], level, "form");
      });
    },

    // select individual item
    toggle_selected(level, item) {
      this.$nextTick(() => {
        const find_item_index = this.form[level].findIndex(
          (obj) => obj == item
        );
        if (find_item_index == -1) {
          this.form[level].push(item);
        } else {
          this.form[level].splice(find_item_index, 1);
        }
        this.updateTempActiveArray();
      });
    },
    // update the foucs level
    focusHandler(level) {
      this.focused_level = level;
      this.select_all_icon = "mdi-minus-box";
      this.updateTempActiveArray();
    },
    // update select combo box
    setTempActiveArray() {
      this.tempSelectItem = [];
      for (
        let i = 0;
        i < this.level_list_form[this.focused_level].length;
        i++
      ) {
        this.tempSelectItem.push(false);
      }
    },
    // update the check box of select combo box
    updateTempActiveArray() {
      const _indexs = this.findindexOfMatch(
        this.form[this.focused_level],
        this.level_list_form[this.focused_level]
      );
      this.setTempActiveArray();
      for (let j = 0; j < _indexs.length; j++) {
        this.tempSelectItem[_indexs[j]] = true;
      }
    },
    // retun match item index
    findindexOfMatch(array_1, array_2) {
      var ary = new Array();
      for (let i = 0; i < array_2.length; i++) {
        for (let j = 0; j < array_1.length; j++) {
          if (array_2[i] == array_1[j]) ary.push(i);
        }
      }
      return ary;
    },

    // filter user list by all levels and search key
    search() {
      if (this.filter_items.search_key == "" || !this.filter_items.search_key) {
        this.temp_users = this.users;
      } else {
        this.temp_users = this.users.filter((x) =>
          x.email.toLowerCase().match(this.filter_items.search_key.toLowerCase()) || 
          x.fullname.toLowerCase().match(this.filter_items.search_key.toLowerCase())
        );
      }
    },

    // form submit
    async submit() {
      try {
        let access_levels = null;
        this.isValidProject = true;
        if(this.form.zone && this.form.zone.length == 0) return;
        if (!this.$refs.form.validate()) return;
        if(this.form.zone[0].id == 'all') {
          access_levels = {
            zone: [],
            state: [],
            subzone: [],
            station: [],
            source: [],
            feeder: [],
          }
        } else {
          access_levels = {
            zones: this.form.zone,
            states: this.form.state,
            subzones: this.form.subzone,
            stations: this.form.station,
            sources: this.form.source,
            feeders: this.form.feeder,
          }
        }

        //add users
        let userDataCreate = {
          email: this.form.email_id,
          fullname: this.form.employee_name,
          role: this.form.role,
          subsidiary: this.form.subsidiary,
          is_active: this.form.is_active == "Active" ? true : false,
          allowWebAccess: this.form.allowWebAccess,
          allowDesktopAccess: this.form.allowDesktopAccess,
          allowProcessingPanel: this.form.allowProcessingPanel,
          password: this.form.password,
          isNewAccessLevel: true,
          access_levels: access_levels,
        };
        this.loading = true;
        let userResponse;
        if (this.editFlag == "edit")
          userResponse = await AdminAPI.put(
            `/users/${this.selectedUserData._id}`,
            userDataCreate
          );
        else userResponse = await AdminAPI.post(`/users`, userDataCreate);
        this.loading = false;
        this.alert(
          "success",
          "",
          userResponse.data.email +' '+ "successfully submitted"
        );
        this.$refs.form.reset();
        this.form.zone = [];
        this.editFlag = null;
        this.isValidProject = false;
        this.getAllUsers();
      } catch (error) {
        this.loading = false;
        this.isValidProject = false;
        this.alert("error", "", error.response.data.msg);
      }
    },

    // get filter level list
    async selectSeacrhChange(value, ind, flag = "") {
      try {
        let getData,
          getRes,
          apiUrl,
          checkStatus = false;
        if (flag == "form") {
          getRes = this.level_list_form;
          apiUrl = "&getlist=array";
          if (value.length > 0) checkStatus = true;
        } else {
          getRes = this.level_list;
          apiUrl = "";
          if (typeof value !== "undefined") checkStatus = true;
        }
        if (checkStatus) {
          if (ind == "zone") {
             this.loading = true;
            getData = await AdminAPI.get(
              `/zones/${value}?showonly=name&searchby=name${apiUrl}`
            );
             this.loading = false;
            getRes.state = getData.data.states;
            // if (flag != "form") this.level_list_filter.zone = value;
          }
          if (ind == "state") {
             this.loading = true;
            getData = await AdminAPI.get(
              `/states/${value}?showonly=name&searchby=name${apiUrl}`
            );
             this.loading = false;
            getRes.subzone = getData.data.places;
            // if (flag != "form") this.level_list_filter.state = value;
          }
          if (ind == "subzone") {
             this.loading = true;
            getData = await AdminAPI.get(
              `/subzones/${value}?showonly=name&searchby=name${apiUrl}`
            );
              this.loading = false;
            getRes.station = getData.data.places;
            // if (flag != "form") this.level_list_filter.subzone = value;
          }
          if (ind == "station") {
             this.loading = true;
            getData = await AdminAPI.get(
              `/stations/${value}?showonly=name&searchby=name${apiUrl}`
            );
             this.loading = false;
            getRes.source = getData.data.places;
            // if (flag != "form") this.level_list_filter.station = value;
          }
          if (ind == "source") {
             this.loading = true;
            getData = await AdminAPI.get(
              `/sources/${value}?showonly=name&searchby=name${apiUrl}`
            );
              this.loading = false;
            getRes.feeder = getData.data.places;
            // if (flag != "form") this.level_list_filter.source = value;
          }
          // if (ind == "feeder") {
          //   if (flag != "form") this.level_list_filter.feeder = value;
          // }
        } else {
          this.emptyTextBoxes(ind, getRes);
          this.emptyTextBoxes(ind, this.filter_items, "val");
        }
        if (flag != "form") {
           this.loading = true;
          let getFilterUser = await AdminAPI.post(
            `/users-filter`,
            this.filter_items
          );
           this.loading = false;
          this.users = getFilterUser.data;
          this.temp_users = [...this.users];
          this.search();
        }
      } catch (error) {
        this.loading = false;
        // console.log("Error: ", error);
        this.alert("error", "", "Error getting user data");
      }
    },
    setDropdownArray(data) {
      let selectBoxArr = data.map((arr) => {
        return { id: arr.name, name: arr.name };
      });
      return selectBoxArr;
    },
    userEditList(data) {
      // this.$data.form.is_active = true;
      this.editFlag = "edit";
      this.selectedUserData = data;
      // this.$refs.form.reset();
      this.form.email_id = data.email;
      this.form.employee_name = data.fullname;
      this.form.role = data.role;
      this.form.team = data.team;
      this.form.department = data.department;
      this.form.is_active = data.is_active ? "Active" : "Not Active";
      this.form.allowWebAccess = data.allowWebAccess;
      this.form.allowDesktopAccess = data.allowDesktopAccess;
      this.form.allowProcessingPanel = data.allowProcessingPanel;
      // this.form.password = data.password
      this.form.subsidiary = data.subsidiary,
      this.form.user_id = data.email;
      // this.form.zone = data.access_levels.zones;
      // console.log("layer zone;;;;;",this.form.zone);
      if(data.access_levels.zones && data.access_levels.zones.length > 0) {
        this.form.zone = data.access_levels.zones;
      } else {
        let obj =[{name:"All Projects", id: 'all'}];
        this.form.zone = obj;
      }
      // let currentUserZoneList = [];
      let currentZoneSection = [];
          if(this.level_list.zone && this.level_list.zone.length > 0 ){
            this.level_list.zone.forEach((value)=>{
                  data.access_levels.zones.forEach((val)=>{
                      if(val._id == value._id){
                          // currentUserZoneList.push(value);
                          currentZoneSection[value.name] = value.sections;
                      }
                  })
              })

          } 
    
        // this.form.zone = currentUserZoneList;
        this.form.section = currentZoneSection;
       
      
      // if (data.access_levels.zones && data.access_levels.zones.length > 0) {
      //   this.level_list.zone = data.access_levels.zones;
      // }
      // this.selectSeacrhChange(data.access_levels.zones, "zone", "form");
      // this.form.state = data.access_levels.states;
      // this.selectSeacrhChange(data.access_levels.states, "state", "form");
      // this.form.subzone = data.access_levels.subzones;
      // this.selectSeacrhChange(data.access_levels.subzones, "subzone", "form");
      // this.form.station = data.access_levels.stations;
      // this.selectSeacrhChange(data.access_levels.stations, "station", "form");
      // this.form.source = data.access_levels.sources;
      // this.selectSeacrhChange(data.access_levels.sources, "source", "form");
      // this.form.feeder = data.access_levels.feeders;
      // this.selectSeacrhChange(data.access_levels.feeders, "feeder", "form");

      // this.updateTempActiveArray();
    },
    async getAllUsers() {
      // let usersList = await AdminAPI.get(`/users`);
       this.loading = true;
      let usersList = await AdminAPI.get(`/listUsersVthSections`);
      usersList = usersList.data;
      if (
        this.loggedUser && this.loggedUser.role == 'Super Admin'
      ) {
        this.users = usersList;
      }
      else if (
        this.loggedUser
      ) {
        // user filter by createdAdmin key
        // this.users = this.users.filter((x) => {
        //   if(x.createdAdmin && x.createdAdmin.length > 0) {
        //     for (const adminID of x.createdAdmin) {
        //       if(adminID == this.loggedUser._id) {
        //         return x;
        //       }
        //     }
        //   } else if (x.createdBy && x.createdBy._id == this.loggedUser._id) {
        //     return x;
        //   }
        // });

        // user filter by subsidiary key
        this.users = usersList.filter((x) => {
          if(x.subsidiary && this.loggedUser) {
            if(this.loggedUser.subsidiary == x.subsidiary) {
              return x;
            }
          }
        });
      } 
      // else if(this.loggedUser && this.loggedUser.role == "AdminClient") {
      //   this.users = this.users.filter((x) => {
      //     if (x.createdBy && x.createdBy._id == this.loggedUser._id ) {
      //       return x;
      //     }
      //   });
      // }
      this.temp_users = [...this.users];
       this.loading = false;
    },
    async getAllSubsidiary() {
      this.loading = true;
      let resp = await AdminAPI.get(`/subsidiary`);
      if (resp.data && resp.data.status) {
        this.allSubsidiary = resp.data.data;
        if(this.loggedUser && this.loggedUser.role == "Admin") {
          this.allSubsidiary = this.allSubsidiary.filter((x)=> {
            if(x._id == this.loggedUser.subsidiary) {
              return x;
            }
          })
        }
        this.loading = false;
      } else {
        this.$swal("Failed!", "Something went wrong!", "error");
      }
    },
  },
  async mounted() {
    //get zones
     this.loading = true;
    let zoneList = await AdminAPI.get(`/zonesVthSections`);
     this.loading = false;
    let obj ={name:"All Projects", id: 'all'}
    zoneList.data.unshift(obj)
    this.level_list.zone = zoneList.data;
    this.level_list_form.zone = zoneList.data;
  
    this.loggedUser = JSON.parse(localStorage.getItem("userData"));
    if (
      this.loggedUser &&
      this.loggedUser.access_levels.zones &&
      this.loggedUser.access_levels.zones.length > 0
    ) {
      this.level_list.zone = this.loggedUser.access_levels.zones;
    }

    //get users
    this.getAllUsers();
    await this.getAllSubsidiary();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.no-data {
  position: absolute;
  top: 45%;
  left: 45%;
}
.list-wrapper:hover {
  background: rgb(255 255 255 / 31%);
}
.list-wrapper {
  transition: 0.3s;
  border-bottom: 1px solid #ffffffab;
}
.active {
  background: #fff;
  color: #000;
}
.active-icon {
  color: #000;
}
.sub-head {
  font-weight: 900;
  margin: 0px 10px;
}
.right-wrapper {
  height: 78vh;
  overflow-y: auto;
}
.heading {
  font-size: 20px;
  font-weight: 500;
}
.wrapper {
  height: 80vh !important;
}
.header {
  display: flex;
  justify-content: space-between;
}
.right-align-btn {
  text-align: right;
}
.right-align-btn .nav-btn {
  margin: 0 0 10px 10px;
}
.select-card {
  height: 400px;
}
.select-card .card-list {
  overflow-y: scroll;
  height: 300px;
}

.card-title {
  font-size: 16px;
  justify-content: center;
  background-color: #808368;
  color: #ffffff;
}
.card-sub-label {
  color: #808368;
  margin: 0 10px;
}
.fliter-box {
  margin: 0 auto;
  max-height: 66px;
}
.pull-left {
  margin-left: 0 !important;
}
.action-button {
  text-align: end;
}
</style>
